import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios';
import store from './store'
import VueDatePicker from '@vuepic/vue-datepicker';
import vuetify from './plugins/vuetify'
// import Cropper from 'cropperjs';
import { loadFonts } from './plugins/webfontloader'

import VueElementLoading from "vue-element-loading";


loadFonts()
const app = createApp(App);
app.component('VueDatePicker', VueDatePicker);
app.component("VueElementLoading", VueElementLoading);

// axios.defaults.baseURL = 'http://192.168.54.104:5055';
// app.config.globalProperties.baseURL = "http://192.168.54.104:5055";
// app.config.globalProperties.mediaURL = "http://192.168.52.74:5055/file/get/"

axios.defaults.baseURL = 'https://holycrossapi.leopardtechlabs.in';
app.config.globalProperties.baseURL = "https://holycrossapi.leopardtechlabs.in";
app.config.globalProperties.mediaURL = "https://holycrossapi.leopardtechlabs.in/file/get/"
    // app.config.globalProperties.mediaURLs = "churchapi.leopardtechlabs.in/wp?key="






createApp(App)
    .use(router)
    .use(store)
    .use(vuetify)
    // .use(Cropper)
    .mount('#app')