import { createRouter, createWebHistory } from 'vue-router'
import store from '../store/index'
import axios from 'axios'
const routes = [{
        path: "/",
        name: "login",
        component: () =>
            import ("../components/Login/LoginPage.vue"),
    },

    {
        path: "/AppsideBar",
        name: "SideBarPage",
        meta: {
            requiresAuth: true
        },
        component: () =>
            import ("../components/Common/newSideBar.vue"),
        children: [{
                path: "/dashboardData",
                name: "dashboardData",
                meta: {
                    requiresAuth: true
                },
                component: () =>
                    import ("../components/Admin/Dashboard/dashboardData.vue"),
            }, {
                path: "/AddWard",
                name: "AddWard",
                meta: {
                    requiresAuth: true
                },
                component: () =>
                    import ("../components/Admin/AddWard.vue"),
            },
            {
                path: "/WardsPage",
                name: "WardsPage",
                meta: {
                    requiresAuth: true
                },
                component: () =>
                    import ("../components/Admin/WardsPage.vue"),
            },
            {
                path: "/familyList",
                name: "familyList",
                meta: {
                    requiresAuth: true
                },
                component: () =>
                    import ("../components/Admin/Family/familyList.vue"),
            },
            {
                path: "/leadersList",
                name: "leadersList",
                meta: {
                    requiresAuth: true
                },
                component: () =>
                    import ("../components/Admin/UnitLeaders/leadersList.vue"),
            },
            {
                path: "/addFamily",
                name: "addFamily",
                meta: {
                    requiresAuth: true
                },
                component: () =>
                    import ("../components/Admin/Family/addFamily.vue"),
            },
            {
                path: "/editFamily",
                name: "editFamily",
                meta: {
                    requiresAuth: true
                },
                component: () =>
                    import ("../components/Admin/Family/editFamily.vue"),
            },
            {
                path: "/familyUpdateApprovel",
                name: "familyUpdateApprovel",
                meta: {
                    requiresAuth: true
                },
                component: () =>
                    import ("../components/Admin/Family/familyUpdateApprovel.vue"),
            },
            {
                path: "/familyUpdateApprovelView",
                name: "familyUpdateApprovelView",
                meta: {
                    requiresAuth: true
                },
                component: () =>
                    import ("../components/Admin/Family/familyUpdateApprovelView.vue"),
            },
            {
                path: "/memberUpdateApprovel",
                name: "memberUpdateApprovel",
                meta: {
                    requiresAuth: true
                },
                component: () =>
                    import ("../components/Admin/Family/memberUpdateApprovel.vue"),
            },
            {
                path: "/memberUpdateApprovelView",
                name: "memberUpdateApprovelView",
                meta: {
                    requiresAuth: true
                },
                component: () =>
                    import ("../components/Admin/Family/memberUpdateApprovelView.vue"),
            },
            {
                path: "/memberAddApproval",
                name: "memberAddApproval",
                meta: {
                    requiresAuth: true
                },
                component: () =>
                    import ("../components/Admin/Family/memberAddApproval.vue"),
            },
            {
                path: "/memberAddApprovalView",
                name: "memberAddApprovalView",
                meta: {
                    requiresAuth: true
                },
                component: () =>
                    import ("../components/Admin/Family/memberAddApprovalView.vue"),
            },
            {
                path: "/memberDeleteApproval",
                name: "memberDeleteApproval",
                meta: {
                    requiresAuth: true
                },
                component: () =>
                    import ("../components/Admin/Family/memberDeleteApproval.vue"),
            },
            {
                path: "/memberDeleteApprovalView",
                name: "memberDeleteApprovalView",
                meta: {
                    requiresAuth: true
                },
                component: () =>
                    import ("../components/Admin/Family/memberDeleteApprovalView.vue"),
            },
            {
                path: "/addMember",
                name: "addMember",
                meta: {
                    requiresAuth: true
                },
                component: () =>
                    import ("../components/Admin/Family/addMember.vue"),
            },
            {
                path: "/ViewDetails",
                name: "ViewDetails",
                meta: {
                    requiresAuth: true
                },
                component: () =>
                    import ("../components/Admin/ViewDetails.vue"),
            },
            {
                path: "/AddFamily",
                name: "AddFamily",
                meta: {
                    requiresAuth: true
                },
                component: () =>
                    import ("../components/Admin/AddFamily.vue"),
            },
            {
                path: "/SearchWard",
                name: "SearchWard",
                meta: {
                    requiresAuth: true
                },
                component: () =>
                    import ("../components/Admin/SearchWard.vue"),
            },
            {
                path: "/ChangePassword",
                name: "ChangePassword",
                meta: {
                    requiresAuth: true
                },
                component: () =>
                    import ("../components/Admin/ChangePasswrod.vue"),
            },
            {
                path: "/MemberDetails",
                name: "MemberDetails",
                meta: {
                    requiresAuth: true
                },
                component: () =>
                    import ("../components/Admin/MemberDetails.vue"),
            },
            {
                path: "/advertisementData",
                name: "advertisementData",
                meta: {
                    requiresAuth: true
                },
                component: () =>
                    import ("../components/Admin/Advertisements/advertisementData.vue"),
            },
            {
                path: "/noticePage",
                name: "noticePage",
                meta: {
                    requiresAuth: true
                },
                component: () =>
                    import ("../components/Admin/Notice/noticePage.vue"),
            },
            {
                path: "/noticeView",
                name: "noticeView",
                meta: {
                    requiresAuth: true
                },
                component: () =>
                    import ("../components/Admin/Notice/noticeView.vue"),
            },
            {
                path: "/aboutPage",
                name: "aboutPage",
                meta: {
                    requiresAuth: true
                },
                component: () =>
                    import ("../components/Admin/About/aboutPage.vue"),
            },
            {
                path: "/representativeList",
                name: "representativeList",
                meta: {
                    requiresAuth: true
                },
                component: () =>
                    import ("../components/Admin/Representative/representativeList.vue"),
            },
            {
                path: "/bloodDonorsList",
                name: "bloodDonorsList",
                meta: {
                    requiresAuth: true
                },
                component: () =>
                    import ("../components/Admin/BloodDonors/bloodDonorsList.vue"),
            },
            {
                path: "/contactList",
                name: "contactList",
                meta: {
                    requiresAuth: true
                },
                component: () =>
                    import ("../components/Admin/Contact/contactList.vue"),
            },
            {
                path: "/communityList",
                name: "communityList",
                meta: {
                    requiresAuth: true
                },
                component: () =>
                    import ("../components/Admin/Community/communityList.vue"),
            },
            {
                path: "/communityView",
                name: "communityView",
                meta: {
                    requiresAuth: true
                },
                component: () =>
                    import ("../components/Admin/Community/communityView.vue"),
            },
            {
                path: "/videosPage",
                name: "videosPage",
                meta: {
                    requiresAuth: true
                },
                component: () =>
                    import ("../components/Admin/Video/videosPage.vue"),
            },
            {
                path: "/sacredOccasion",
                name: "sacredOccasion",
                meta: {
                    requiresAuth: true
                },
                component: () =>
                    import ("../components/Admin/SacredOccasion/sacredOccasion.vue"),
            },
            {
                path: "/subAdmins",
                name: "subAdmins",
                meta: {
                    requiresAuth: true
                },
                component: () =>
                    import ("../components/Admin/SubAdmin/subAdmins.vue"),
            },
            {
                path: "/administratorList",
                name: "administratorList",
                meta: {
                    requiresAuth: true
                },
                component: () =>
                    import ("../components/Admin/Administrator/administratorList.vue"),
            },
            {
                path: "/prayerRequestList",
                name: "prayerRequestList",
                meta: {
                    requiresAuth: true
                },
                component: () =>
                    import ("../components/Admin/PrayerRequest/prayerRequestList.vue"),
            },
            {
                path: "/messagesList",
                name: "messagesList",
                meta: {
                    requiresAuth: true
                },
                component: () =>
                    import ("../components/Admin/Message/messagesList.vue"),
            },
            {
                path: "/bulletIn",
                name: "bulletIn",
                meta: {
                    requiresAuth: true
                },
                component: () =>
                    import ("../components/Admin/BulletIn/bulletIn.vue"),
            },
            {
                path: "/bulletView",
                name: "bulletView",
                meta: {
                    requiresAuth: true
                },
                component: () =>
                    import ("../components/Admin/BulletIn/bulletView.vue"),
            },

        ]
    },
    {
        path: "/:catchAll(.*)",
        name: "notfound",
        component: () =>
            import ("../components/Common/notFound404.vue"), // Create this component

    },

    {
        path: "/serverError500",
        name: "servererror",
        component: () =>
            import ("../components/Common/serverError500.vue"),
    },
    {
        path: "/privacyPolicy",
        name: "privacyPolicy",
        component: () =>
            import ("../components/Common/privacyPolicy.vue"),
    },
    {
        path: "/supportPage",
        name: "supportPage",
        component: () =>
            import ("../components/Common/supportPage.vue"),
    },
]

axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response && error.response.status === 500) {
            router.push({ name: 'servererror' });
        }
        return Promise.reject(error);
    }
);

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            // If a saved position is available, use it
            return savedPosition;
        } else {
            // Scroll to the top of the page
            return { top: 0 };
        }
    }
})
router.beforeEach((to, from, next) => {
    const leavingPage = ['familyList', 'MemberDetails'].includes(from.name);

    // Check if not entering any of these pages
    const notEnteringPage = !['familyList', 'MemberDetails'].includes(to.name);

    // If leaving any of these pages and not entering the same type of page
    if (leavingPage && notEnteringPage) {
        // Commit a Vuex store mutation to change the current page to an empty string
        store.commit('changeCurrentPage', '');
        store.commit('changeUnit', '');
        // console.log('Change current page to an empty string');
    }
    if (store.state.isLoggedIn == true || to.matched.some(route => route.meta.requiresAuth == true)) {
        axios({
                method: 'get',
                url: '/authenticate',
                headers: {
                    'token': localStorage.getItem('token')
                }
            }).then(response => {
                if (!response.data.status) {
                    console.log("router logiut")
                    store.state.isLoggedIn = false;
                    localStorage.clear();
                    router.push("/");
                    // this.$store.commit("logoutUser", true);
                    return
                }
            })
            .catch(err => {
                var msg = err;
                console.log(msg)
            });
    }

    if (
        to.matched.some((route) => route.meta.requiresAuth == true) &&
        store.state.isLoggedIn == false
    ) {
        console.log("1");
        next({ name: "login", params: { lastPage: from } });
        return;
    }
    if (to.name === "login" && store.state.isLoggedIn == true) {
        console.log("2");
        next({ name: "dashboardData" });
    }

    if (
        to.matched.some((route) => route.meta.isAdmin == false) && from.name != "login" &&
        store.state.isLoggedIn == true
    ) {
        next({ name: "dashboardData" });
        return;
    } else {
        next();
    }
});



export default router