import { createStore } from 'vuex'
import router from '@/router';
import axios from 'axios'

export default createStore({
    state: {
        isLoggedIn: !!localStorage.getItem("token"),
        role: localStorage.getItem("role"),
        userData: {},
        currentPage: null,
        unitId: "",
    },
    getters: {},
    mutations: {
        loginUser(state, payload) {
            localStorage.setItem("token", payload);
            state.isLoggedIn = true
        },
        role(state, payload) {
            state.role = payload
        },
        logoutUser(state) {
            axios({
                method: "POST",
                url: "/user/logout",
                headers: {
                    "token": localStorage.getItem("token")
                }
            }).then(response => {
                if (response.data) {
                    state.isLoggedIn = false
                    state.currentPage = null
                    localStorage.clear();
                    // localStorage.removeItem("token");
                    router.push('/')
                }
            })
        },
        userData(state, item) {
            state.userData = item;
        },
        changeCurrentPage(state, item) {
            state.currentPage = item
        },
        changeUnit(state, item) {
            state.unitId = item
        },

    },
    actions: {},
    modules: {}
})